th {
    min-width: 100px;
}

th.tracker-col {
    min-width: 175px;
}

.trackerHeader> {}

td:hover {
    background: lightgrey;
}

.action-cell {
    margin-left: 3px
}

.button-fa {
    margin-right: 5px;
	padding-top: 3px;
    font-size: medium;
}

.buyer-exited {
    color: grey;
    background-color: lightgrey;
}

.tracker-board-cell {
    padding: 0px;
}

/* (A) TABLE WRAPPER */
#demoDW {
    overflow: auto;
}

/* (B) STICKY HEADERS */
.stick {
    background: white;
    position: sticky;
    top: 0;
    z-index: 3;
    opacity: 1;
    background-color: white !important;
}