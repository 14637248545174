/* Global CSS, you probably don't need that */

.clearfix:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
}

.arrowcontainer {
    width: 100%;
    margin-left: 5px;
}

.wrapper {
    vertical-align: middle;
}

.nav {
    margin-top: 40px;
}

.pull-right {
    float: right;
}

a,
a:active {
    color: #333;
    text-decoration: none;
}

a:hover {
    color: #999;
}

/* Breadcrups CSS */

.arrow-steps .step {
    white-space: nowrap;
    font-size: 14px;
    text-align: center;
    color: #666;
    cursor: default;
    margin: 0 3px;
    padding-top: 10px;
    padding-bottom: 5px;
    min-width: 120px;
    float: left;
    position: relative;
    background-color: #d9e3f7;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -17px;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 17px solid #d9e3f7;
    z-index: 2;
    transition: border-color 0.2s ease;
}

.bg-success .step:after {}

.arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 17px solid #fff;
    z-index: 0;
}

.arrow-steps .step:first-child:before {
    border: none;
}

.arrow-steps .step:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
    position: relative;
    z-index: 3;
}

.arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -20px;
}

.arrow-steps .step.done span:before {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease 0.5s;
    -moz-transition: opacity 0.3s ease 0.5s;
    -ms-transition: opacity 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
    color: #fff;
    background-color: #23468c;
}

.arrow-steps .step.current:after {
    border-left: 17px solid #23468c;
}

.arrow-steps .step.bg-success:after {
    border-left: 17px solid #46bcaa;
}

.phase-modal-button-div {
    height: 100%;
    float: left;
    /* width: 17%; */
    margin-left: 20px;
    width: 20px;
    text-align: center;
    padding-top: 1px;
    padding-bottom: 5px;
    float: left;
    font-size: large;
}

.phase-modal-button {
    font-size: large;
}

.activephase {

    animation: move 2s linear infinite;
    background-image: linear-gradient(-45deg,
            rgba(255, 255, 255, 0.2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0.2) 75%,
            transparent 75%,
            transparent);
}

@keyframes move {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 100px 100px;
    }
}
