.projects-row {

}

/* The heart of the matter */
.testimonial-group>.row {
}

.testimonial-group>.row>.col-md-4 {
    display: inline-block;
    float: none;
}

.addnew-button {
    min-height: 177px;
    min-width: 245px;
}