.projects-row {}

/* The heart of the matter */
.testimonial-group>.row {}

.testimonial-group>.row>.col-md-4 {
    display: inline-block;
    float: none;
}

.addnew-button {
    min-height: 177px;
    min-width: 245px;
}


.task-tit {
    line-height: 1.5em;
    height: 3em;       /* height is 2x line-height, so two lines will display */
    overflow: hidden;  /* prevents extra lines from being visible */
}

.task-tit:focus {
    line-height: initial;
    height: initial;       /* height is 2x line-height, so two lines will display */
    overflow:initial;  /* prevents extra lines from being visible */
}

.task-tit-ellipses {

}

.task-tit-div:focus .task-tit-ellipses{
    display: none;
}

.task-tit-2 {
    line-height: 1.5em;
    height: 3em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}