.workstream-title
{
    min-width: 50px;
}

.title-empty
{
    border-style: solid;
    border-color: red;
    display: inline-block;
}