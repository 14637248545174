/*Task Table*/

.tasktable-wrapper td {
	padding: 2px;
}

.tasktable-wrapper tr:hover {
	background-color: lightgray;
}

.tasktable-wrapper .table-group-row:hover {
	color: inherit;
}

.dropdown .full-cell-div {
	padding-top: 5px;
}

.react-datepicker-wrapper input {
	background-clip: border-box;
	background-color: rgba(0, 0, 0, 0);
	background-image: none;
	background-origin: padding-box;
	background-position-x: 0%;
	background-position-y: 0%;
	background-size: auto;
	border-bottom-color: rgb(118, 118, 118);
	border-bottom-width: 1px;
	border-collapse: collapse;
	border-image-outset: 0;
	border-image-repeat: stretch;
	border-image-slice: 100%;
	border-image-source: none;
	border-image-width: 1;
	border-left-color: rgba(0, 0, 0, 0.87);
	border-left-style: none;
	border-left-width: 0px;
	border-right-color: rgba(0, 0, 0, 0.87);
	border-right-style: none;
	border-right-width: 0px;
	border-top-color: rgba(0, 0, 0, 0.87);
	border-top-style: none;
	border-top-width: 0px;

	max-width: 90px;
	padding: 2px;
}

.split-task {
	min-height: 100%;
}

/*-----------------
	1. Material Table
-----------------------*/

.MuiTableCell-footer {
	border-radius: 4px;
}

.MuiPaper-root {
	font-size: 15px;
	font-weight: 300;
	line-height: .875rem;
}

.MuiIconButton-label {
	font-size: 15px;
	font-weight: 500;
}

.taskTableComments {
	margin-right: 7px;
}

.task-complete>* {
	color: grey;
	background-color: lightgrey;
	text-decoration: line-through;
}

.task-complete {
	text-decoration: line-through;
}

.task-label:hover {
	border-bottom: 1px dotted darkgray
}

div.MuiChip-root {
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

.add-task-btn {
	margin-right: 10px
}

.task-table-pane {
	border-right: 1px solid darkgray
}

.tagSelect>div {
	border-top: none;
	border-right: none;
	border-left: none;
	border-radius: unset;
}

[class*="-multiValue"] {
	border-radius: 15px;
}


.tagSelect>[class*="-control"] {
	background-color: inherit;
}

.chat-wrap-inner {
	font-variant: tabular-nums;
	font-feature-settings: 'tnum';
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	font-family: 'CircularStd', sans-serif;
	box-sizing: border-box;
	bottom: 0;
	left: 0;
	overflow: auto;
	right: 0;
	top: 0;
}

.chat-box {
	font-variant: tabular-nums;
	font-feature-settings: 'tnum';
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	font-family: 'CircularStd', sans-serif;
	box-sizing: border-box;
}

.task-row {
	vertical-align: middle;
}

.task-row>td {
	padding: 1px;
}

.task-action-btn>span {
	padding-right: 3px;
	font-size: large;
}

.select-cell {
	max-width: 420px;
	width: 15%;
}

.task-wrapper {
	margin-bottom: 10px;
}

.task-row:hover {
	background-color: rgb(230, 230, 230);
}

.task-action-btn {
	width: 5px;
}

.task-row:hover>.task-action-btn {
	visibility: inherit;
}

.task-action-btn>div>span {
	margin-right: 3px;
}

span.task-label:hover {
	border: black;
	border-bottom-style: solid;
}

.add-task-row {
	color: darkgrey;
}

.add-task-row:hover>td>span {
	border: darkgrey;
	border-bottom-style: solid;
}

.title-column-span:focus {
	border: none;
	border-bottom-style: none;
}

.work-stream-actions {
	visibility: hidden;
}

.workstream-bar {
	display: inline-block;
	vertical-align: bottom;
}

.workstream-bar>* {
	margin-right: 10px;
	float: left
}

.workstream-bar-section:hover .work-stream-actions {
	visibility: visible;
}

.workstream-bar-section:hover .workstream-drag-grip {
	visibility: visible;
}

.workstream-drag-grip {
	visibility: hidden;
}

.workstream-complete-all:hover {
	color: darkgreen
}

.workstream-save-template:hover {
	color: darkkhaki
}

.workstream-delete:hover {
	color: red
}

.action-set {
	display: flex;
	font-size: x-large;
	margin-top: 0px;
}

.action-set button {
	display: flex;
	margin-right: 7px
}

.action-set svg {
	cursor: pointer;
	margin-right: 10px;
	flex: 1;
	float: left
}

.dropdown {
	display: flex
}

.need-press {
	border-radius: 50%;

	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

.wi-status-closed {
	margin-right: 4px;
	display: none
}

.workstream-bar svg {
	cursor: pointer;
	margin-right: 10px;
}

.workstream-bar-complete {
	color: darkgreen
}

.workstream-bar-complete .wi-status-closed {
	display: inline
}

.ws-collapsed .task-list-container {
	display: none
}

fieldset.MuiOutlinedInput-notchedOutline {
	border: none;
}

.avatarImg {
	border-radius: 50%;
	margin-right: 3px;
}

input.MuiOutlinedInput-input {
	border-top: none;
	border-right: none;
	border-left: none;
	border-radius: unset;
	padding: 0;
	border-bottom-style: solid;
	border-bottom-width: thin;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 lightgreen;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px lightgreen;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 lightgreen;
	}
}

.fixedHeighTable {
	table-layout: fixed;
}

.fixedHeighTable td {
	height: 30px;
	overflow: hidden;
}

.taskDesc {
	border: none;
}

.taskDesc:hover {
	border-style: solid;
}

.button-fa {
	margin-right: 5px;
	padding-top: 3px;
	font-size: medium;
}

.addNewTaskBox {
	border: 0;
	background-color: inherit;
}

.addNewTaskBox:hover {
	border-bottom: dashed;
	border-bottom-width: 1px
}

.ws-container {
	margin: 0px;
}

.newWS {
	font-size: xx-large;
	background-color: white;
}

.table-c {}

.chat-wrap-inner {
	background-color: white;
}

.sub-task-section {}

.st-icon {
	margin-right: 3px
}

.drag-handles {}

.hover-hidden {
	visibility: hidden;
}

.st:hover .hover-hidden {
	visibility: inherit;
}

.st-complete {
	color: grey;
	background-color: lightgrey;
	text-decoration: line-through;
}

.st-complete .st-completion-button {
	color: darkgreen;
}

.task-grip {
	visibility: hidden;
}

.task-row:hover .task-grip {
	visibility: inherit;
}

.task-complete {
	color: lightgrey
}

.task-tit-div svg {
	float: left;
}

.at-td {
	border: none
}