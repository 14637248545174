.App {
    /* display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; */
}

.list-container {
    display: flex;
    /* font-size: 18px; */
    background-color: #eee;
    flex-direction: column;
}

.item-container {
    background-color: #fff;
    padding: 5px 5px;
    margin: 5px 5px;
}